<script setup lang="ts">
import MainLogo from '~/components/MainLogo.vue'

const route = useRoute()
const menuOpen = ref(false)

watch(
  () => route.path,
  () => {
    menuOpen.value = false
  }
)

function menuToggle() {
  menuOpen.value = !menuOpen.value
}
</script>

<template>
  <div
    class="z-40 p-4"
    :class="{
      'bg-zeno-gray-light h-svh fixed top-0 right-0 bottom-0 left-0 flex flex-col space-y-4': menuOpen,
    }"
  >
    <div class="flex gap-4 justify-between items-center">
      <MainLogo />

      <div class="flex gap-2 items-center">
        <!--        <a-->
        <!--          :href="isAndroid ? googlePlayUrl() : appStoreUrl()"-->
        <!--          target="_blank"-->
        <!--          class="rounded-full px-3 py-1.5 bg-zeno-yellow"-->
        <!--          >Download App</a-->
        <!--        >-->

        <button
          v-if="!menuOpen"
          class="rounded-full px-3 py-2 bg-white flex"
          aria-label="open menu"
          @click="menuToggle"
        >
          <Icon size="20" name="solar:hamburger-menu-linear" />
        </button>

        <button v-else class="rounded-full px-3 py-2 bg-white flex z-50" aria-label="close menu" @click="menuToggle">
          <Icon size="20" name="ic:round-close" />
        </button>
      </div>
    </div>

    <div v-if="menuOpen" class="overflow-y-scroll">
      <MainNavigation />

      <!--      <NuxtLink :to="loginUrl()" class="bg-zeno-yellow rounded-xl py-3 px-4 block text-center mt-4">-->
      <!--        Download app-->
      <!--      </NuxtLink>-->
    </div>
  </div>

  <!--  <Transition>-->
  <!--    <div v-if="menuOpen" class="z-40 fixed top-0 right-0 bottom-0 left-0 h-screen bg-zeno-gray-light">-->
  <!--      <div class="p-5 w-full">-->
  <!--        <MainNavigation />-->

  <!--        <NuxtLink :to="loginUrl()" class="bg-zeno-yellow rounded-xl py-3 px-4 block text-center mt-4">-->
  <!--          Download app-->
  <!--        </NuxtLink>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </Transition>-->
</template>

<style lang="postcss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
